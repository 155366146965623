import revive_payload_client_XXDI5Oz4Kk from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.5.4_@unocss+r_f4b99d3a2d05cfa7df8e3bb9a6c735bc/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Cz51fYEk3Y from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.5.4_@unocss+r_f4b99d3a2d05cfa7df8e3bb9a6c735bc/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_4XNRaIa4pG from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.5.4_@unocss+r_f4b99d3a2d05cfa7df8e3bb9a6c735bc/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VOPM6YtmW8 from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.5.4_@unocss+r_f4b99d3a2d05cfa7df8e3bb9a6c735bc/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_jlBffTP6dy from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.5.4_@unocss+r_f4b99d3a2d05cfa7df8e3bb9a6c735bc/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_E9LZuaWOK0 from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.5.4_@unocss+r_f4b99d3a2d05cfa7df8e3bb9a6c735bc/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_nAZeCXQMfh from "/app/node_modules/.pnpm/@hebilicious+vue-query-nuxt@0.3.0_@tanstack+vue-query@5.45.0_vue@3.4.29_typescript@5.4._dd2aa523fc87c16c60fa210a054144d7/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import i18n_nFX2ksDGeR from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.18.0_vue@3.4.29_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_LB2PXkEQ0y from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.5.4_@unocss+r_f4b99d3a2d05cfa7df8e3bb9a6c735bc/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import chart_client_qQ92gke75l from "/app/plugins/chart.client.ts";
import crypto_icon_client_becPFXJNXp from "/app/plugins/crypto-icon.client.ts";
import numeric_client_UFj4kjWu3N from "/app/plugins/numeric.client.ts";
import toastify_client_zzPWHgjcj4 from "/app/plugins/toastify.client.ts";
export default [
  revive_payload_client_XXDI5Oz4Kk,
  unhead_Cz51fYEk3Y,
  router_4XNRaIa4pG,
  payload_client_VOPM6YtmW8,
  check_outdated_build_client_jlBffTP6dy,
  components_plugin_KR1HBZs4kY,
  prefetch_client_E9LZuaWOK0,
  plugin_nAZeCXQMfh,
  i18n_nFX2ksDGeR,
  chunk_reload_client_LB2PXkEQ0y,
  chart_client_qQ92gke75l,
  crypto_icon_client_becPFXJNXp,
  numeric_client_UFj4kjWu3N,
  toastify_client_zzPWHgjcj4
]