import validate from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.5.4_@unocss+r_f4b99d3a2d05cfa7df8e3bb9a6c735bc/node_modules/nuxt/dist/pages/runtime/validate.js";
import setup_45global from "/app/middleware/setup.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.5.4_@unocss+r_f4b99d3a2d05cfa7df8e3bb9a6c735bc/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  setup_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/middleware/auth.ts")
}